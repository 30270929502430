import * as React from "react";
import Navbar from "../sections/Navbar/Navbar";
import "../styles/index.css";
import "../styles/colour.css";
import "../styles/fonts.css";
// @ts-ignore
import LiviaImg from "../assets/img/livia.png";

import Footer from "../sections/Footer";

import CoachFeatures from "../sections/CoachFeaturesFr";
import CoachProfile from "../components/old/CoachProfile/CoachProfile";

import MeetCoaches from "../sections/MeetCoaches/MeetCoachesFr";
import YouAreCoach from "../sections/YouAreCoachFr";
import GlobalExcellence from "../sections/GlobalExcellenceFr";
// @ts-ignore
import Seo from "../utils/seo";
// @ts-ignore
import Layout from "../layouts";
// markup
const CoachesPage = () => {
  return (
    <>
      <Seo title="Coachello - Coaches" />
      <Layout>
        <GlobalExcellence />
        <CoachFeatures />
        <MeetCoaches />
        <YouAreCoach />
      </Layout>
    </>
  );
};

export default CoachesPage;
